import React from 'react'
import { Helmet } from 'react-helmet'

import s from "./index.module.scss"
import Layout from '../components/layouts'


const RenderBody = ({ product, defaultVariant, variants }) => {

  return (
    <React.Fragment>
  
      <div className={s.cartContainer}>

        <h1 className="text-center mb-5 mt-5">
          Thank you!
        </h1>

        <p className="text-center lead mb-5">
          Thank you for buying one of my prints! I will be in contact with you by email in the next 24 hours to confirm your purchase. If you have any question, don't hesitate to contact me by email at loic@lbacreations.com.
        </p>

  
      </div>
  
    </React.Fragment>
  )
}

const Product = props => {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cart</title>
      </Helmet>
      <RenderBody />
    </Layout>
  )
}

export default Product